import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import ProtectedRoute from "./components/ProtectedRoute";
import { Login, Setup, ChatPage } from "./pages";

const App = () => {
  return (
    <div className="App">
      <section className="container">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />

        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<ProtectedRoute />}>
              <Route path="/chat" element={<ChatPage />} />
              <Route path="/" element={<Setup />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </section>
    </div>
  );
};

export default App;
