import React, { useEffect, useState } from "react";
import { getModels } from "../services/ApiService";
import { Header, Form } from "../components";
import { useGlobalContext } from "../context/context";

export const Setup = () => {
  const [availableModels, setAvailableModels] = useState([]);
  const { dispatch } = useGlobalContext();

  useEffect(() => {
    (async () => {
      const models = await getModels(dispatch);
      setAvailableModels(models);
    })();
  }, []);

  return (
    <>
      <Header />
      <Form models={availableModels} />
    </>
  );
};
