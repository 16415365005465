import { Link } from "react-router-dom";
import { USER_DEFAULT_IMAGE } from "../common";
import { useState } from "react";
import { useGlobalContext } from "../context/context";
import { Logout } from "./index";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { user } = useGlobalContext();

  const handleAvatarClick = () => {
    setShowMenu(!showMenu);
  };

  return (
    <header>
      <div className="logo">
        <h1>AIUTANTE</h1>
      </div>

      <div className="menu">
        <nav className="menu-info">
          <Link to="/">Model Setup</Link>

          <div className="user">
            <span>{user?.name || "Utente"}</span>
            <img
              className="avatar avatar-header"
              src={user?.img || USER_DEFAULT_IMAGE}
              alt="avatar"
              onClick={handleAvatarClick}
            />
          </div>
        </nav>

        {showMenu && <Logout />}
      </div>
    </header>
  );
};
export default Header;
