import { GREETINGS, INITIAL_MESSAGES, SENDER, TIMESTAMP } from "../common";

export const messagesInit = (
  setMessages,
  messages,
  setQuestion,
  question,
  setAnswer,
  answer,
) => {
  setMessages([
    ...messages,
    {
      sender: SENDER.bot,
      text: answer,
      timestamp: TIMESTAMP,
    },
    {
      sender: SENDER.user,
      text: question,
      timestamp: TIMESTAMP,
    },
  ]);

  setQuestion("");
  setAnswer("");
};

export const getRandomGreeting = () => {
  return GREETINGS[Math.floor(Math.random() * GREETINGS.length)];
};

export const getRandomMessage = () => {
  return INITIAL_MESSAGES[Math.floor(Math.random() * INITIAL_MESSAGES.length)];
};
