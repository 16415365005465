import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { showErrorToast } from "../helpers/Error";
import { useGlobalContext } from "../context/context";
import { LOGIN } from "../context/actions";

export const Login = () => {
  const navigate = useNavigate();
  const { dispatch } = useGlobalContext();

  const handleLoginSuccess = (credentialResponse) => {
    localStorage.setItem("token", credentialResponse.credential);
    dispatch({ type: LOGIN, payload: credentialResponse.credential });
    navigate("/");
  };

  const handleLoginError = () => {
    console.log("Login Failed");
    showErrorToast();
  };

  return (
    <div className="login-container">
      <div className="login-logo">
        <img
          src={process.env.PUBLIC_URL + "/img/logo.png"}
          alt="Logo"
          className="logo"
        />
      </div>

      <h1>AIUTANTE</h1>
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          handleLoginSuccess(credentialResponse);
        }}
        onError={() => {
          handleLoginError();
        }}
      />
    </div>
  );
};
