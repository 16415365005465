import React from "react";
import { Chat, Header } from "../components";

export const ChatPage = () => {
  return (
    <>
      <Header />
      <Chat />
    </>
  );
};
