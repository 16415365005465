import React, { useState } from "react";
import { MESSAGE_PLACEHOLDER } from "../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";

const InputBar = ({ handleSubmit, setQuestion, question, token }) => {
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className={`input-container ${isFocused ? "focused" : ""}`}>
      <input
        type="text"
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        placeholder={MESSAGE_PLACEHOLDER}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSubmit();
          }
        }}
      />

      <button disabled={!token} onClick={handleSubmit}>
        <FontAwesomeIcon
          className={`icon ${isFocused ? "fa-fade" : ""}`}
          icon={faPaperPlane}
        />
      </button>
    </div>
  );
};

export default InputBar;
