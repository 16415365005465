import React, { useEffect, useState } from "react";
import { SENDER, USER_DEFAULT_IMAGE } from "../common";
import ReactMarkdown from "react-markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faCopy } from "@fortawesome/free-regular-svg-icons";
import { useGlobalContext } from "../context/context";

const Message = ({ sender, text, timestamp, cssClass }) => {
  const { user } = useGlobalContext();
  const avatar = user?.img || USER_DEFAULT_IMAGE;
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [copied]);

  return (
    <div className={`message message-${sender} ${cssClass || ""}`}>
      <img
        src={
          sender === SENDER.bot
            ? process.env.PUBLIC_URL + "/img/logo.png"
            : avatar
        }
        alt="Avatar"
        className="avatar avatar-chat"
      />

      <div className="message-content">
        <div className="message-text">
          <ReactMarkdown className="markdown">{text}</ReactMarkdown>
          <span className="timestamp">{timestamp}</span>
        </div>

        {sender === SENDER.bot && (
          <button
            className="copy-button"
            onClick={() => {
              navigator.clipboard
                .writeText(text)
                .then(() => setCopied(true))
                .catch((error) => console.log(error));
            }}
          >
            <FontAwesomeIcon icon={copied ? faCheckSquare : faCopy} />
          </button>
        )}
      </div>
    </div>
  );
};

export default Message;
