import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useGlobalContext } from "../context/context";
import { getChatID } from "../services/ApiService";

const Form = ({ models }) => {
  const [apiKey, setApiKey] = useState("");
  const [model, setModel] = useState("");
  const { dispatch } = useGlobalContext();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch({ type: "SET_API_KEY", payload: apiKey });
    dispatch({ type: "SET_MODEL", payload: model });

    const response = await getChatID(
      localStorage.getItem("token"),
      apiKey,
      dispatch,
    );

    if (response && response["_id"]) {
      dispatch({ type: "SET_CHAT_ID", payload: response["_id"] });
    }

    navigate("/chat");
  };

  return (
    <div className="form-container">
      <div className="form-title">
        <h1>Ci siamo quasi!</h1>
        <p>Un paio di informazioni prima di cominciare</p>
      </div>

      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={apiKey}
          onChange={(event) => setApiKey(event.target.value)}
          placeholder="Inserisci la tua API Key"
          required
        />

        <select
          value={model}
          onChange={(event) => setModel(event.target.value)}
          required
        >
          <option value="" disabled selected>
            Seleziona il modello
          </option>
          {models.map((model, index) => (
            <option key={index} value={model.id}>
              {model}
            </option>
          ))}
        </select>

        <button className="button" type="submit">
          Inizia a chattare!
        </button>
      </form>
    </div>
  );
};
export default Form;
