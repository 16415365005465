import axios from "axios";
import { showErrorToast } from "../helpers/Error";

export const fetchAnswer = async (
  setAnswer,
  token,
  question,
  setIsLoading,
  model,
  apiKey,
  chatId,
) => {
  const payload = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-api-key": apiKey,
    },
    body: JSON.stringify({
      question,
    }),
  };

  const body = {
    question,
    modelName: model,
  };

  if (chatId) {
    body.chatId = chatId;
  }

  try {
    const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/chat`, {
      method: "POST",
      headers: payload.headers,
      body: JSON.stringify(body),
    });

    if (!res.ok) {
      setIsLoading(false);
      showErrorToast();
      return;
    }

    const reader = res.body.getReader();

    const readChunk = () => {
      reader.read().then(({ value, done }) => {
        if (!done) {
          if (value) {
            setIsLoading(false);
            const decoder = new TextDecoder();
            const decodedValue = decoder.decode(value, { stream: true });
            setAnswer((prevResponse) => prevResponse + decodedValue);
          }

          // Leggi il prossimo chunk
          readChunk();
        } else {
          setIsLoading(false);
          setAnswer((prevResponse) => prevResponse);
        }
      });
    };

    readChunk();
  } catch (err) {
    setIsLoading(false);
    throw Error("Something went wrong");
  }
};

export const getChatID = async (token, apiKey) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/chatId`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-api-key": apiKey,
        },
      },
    );

    return response.data;
  } catch (err) {
    console.log(err);
    showErrorToast();
  }
};

export const getModels = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/models`,
    );

    return response.data;
  } catch (err) {
    showErrorToast();
  }
};
