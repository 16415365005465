import { jwtDecode } from "jwt-decode";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "LOGIN":
      const decodedToken = jwtDecode(payload);
      return {
        ...state,
        user: {
          name: decodedToken.given_name,
          img: decodedToken.picture,
        },
      };
    case "SET_MODEL":
      return {
        ...state,
        model: payload,
      };
    case "SET_CHAT_ID":
      return {
        ...state,
        chatId: payload,
      };
    case "SET_API_KEY":
      return {
        ...state,
        apiKey: payload,
      };
    case "RESTORE_STATE":
      return { ...payload };
    default:
      return state;
  }
};

export default reducer;
