export const BOT_NAME = "AIutante";
export const MESSAGE_PLACEHOLDER = "Scrivi la tua richiesta...";
export const MESSAGE_SEND_BUTTON = "send";
export const SENDER = {
  user: "user",
  bot: BOT_NAME,
};

export const GREETINGS = ["Ciao"];
export const INITIAL_MESSAGES = [
  "cosa posso fare per te?",
  "come posso aiutarti?",
  "che posso fare per te?",
];
export const TIMESTAMP = new Date().toLocaleString("it-IT");
export const USER_DEFAULT_IMAGE =
  "https://t4.ftcdn.net/jpg/05/49/98/39/360_F_549983970_bRCkYfk0P6PP5fKbMhZMIb07mCJ6esXL.jpg";
