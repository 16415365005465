import React, { useState, useEffect, useRef } from "react";
import { BOT_NAME, TIMESTAMP } from "../common";
import InputBar from "./InputBar";
import Message from "./Message";
import { fetchAnswer, getChatID } from "../services/ApiService";
import {
  getRandomGreeting,
  getRandomMessage,
  messagesInit,
} from "../helpers/Message";
import { DotLoader } from "react-spinners";
import { useGlobalContext } from "../context/context";
import reducer from "../context/reducer";

const Chat = () => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { chatId, apiKey, model } = useGlobalContext();
  const messagesEndRef = useRef(null);
  const token = localStorage.getItem("token");

  // Handle scroll
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [answer]);

  // Handle random greetings
  useEffect(() => {
    const message = getRandomMessage();
    setAnswer(
      `${getRandomGreeting()} ${
        sessionStorage.getItem("name")
          ? sessionStorage.getItem("name") + ", " + message
          : message
      }`,
    );
  }, []);
  const handleSubmit = async (e) => {
    setIsLoading(true);

    messagesInit(
      setMessages,
      messages,
      setQuestion,
      question,
      setAnswer,
      answer,
    );

    await fetchAnswer(
      setAnswer,
      token,
      question,
      setIsLoading,
      model,
      apiKey,
      chatId,
    );
  };

  return (
    <>
      <div className="chat-container">
        <div className="messages-container">
          {messages.map((message, index) => (
            <Message key={index} {...message} />
          ))}

          {answer && (
            <Message
              {...{
                sender: BOT_NAME,
                text: answer,
                timestamp: TIMESTAMP,
                cssClass: "last-message",
              }}
            />
          )}

          <div className="messages-end" ref={messagesEndRef} />

          <div className="loader">
            <DotLoader
              color="#ffffff"
              loading={isLoading}
              size={23}
              aria-label="Loading Spinner"
              data-testid="loader"
              cssOverride={{
                display: "block",
              }}
            />
          </div>
        </div>

        <InputBar
          handleSubmit={handleSubmit}
          setQuestion={setQuestion}
          token={token}
          question={question}
        />
      </div>
    </>
  );
};

export default Chat;
